/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:d64e8293-71a1-4f82-a5b1-0952d69e708b",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_whpz3nojd",
    "aws_user_pools_web_client_id": "239a8agl2ugb3ie7cpnaf3975l",
    "oauth": {},
    "aws_cognito_login_mechanisms": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_appsync_graphqlEndpoint": "https://mwn47kpgdndytl6xumrgicc4em.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-klnjuoxhajgkhgootatdfwd3fq",
    "aws_user_files_s3_bucket": "amplidarkyard121628-staging",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
